// REACT
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// CONTEXT AND HOOKS
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'
// COMPONENTS
import { Alert } from '../../../components/editor/Alert'
import Modal from '../../../components/editor/Modal'
import ModalDelete from '../../../components/editor/ModalDelete'
import { ErrorPerms } from '../../../components/editor/ErrorPerms'
import TitleAdministratorAndMenu from '../../../components/editor/TitleAdministratorAndMenu'
import { BackButton } from '../../../components/editor/BackButton'
import { ListDecorationsScene } from '../../../components/editor/design/DecorationsScene/ListDecorationsScene'
import { ListDoorScene } from '../../../components/editor/design/DoorsScene/ListDoorScene'
import { NpcAppearanceScene } from '../../../components/editor/design/NpcAppearanceScene/NpcAppearanceScene'
import { ListStoryTeller } from '../../../components/editor/design/StoryTeller/ListStoryTeller'
// UTILITIES
import '../../../styles/ObjectsAdmin.scss'
import {
	API_MANAGE_SCENE_WORLDS,
	API_MANAGE_SCENE_SUB_WORLDS,
	API_MANAGE_LIST_LEVELS_LANGUAGES,
} from '../../../utils/constantsAdmin'
import myCookies from '../../../utils/myCookies'
import { USER_API } from '../../../utils/constants'
import {
	ManageStorageGameLanguage,
	ManageStorageLevelLanguage,
} from '../../../utils/manage_local_storage'

export default function ManageSceneWorld() {
	return useAuthNavigator(<PrivatePage />)
}

function PrivatePage() {
	// AUTH PERMISSION
	const { gameUser, setGameUser, axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { getLevelLanguage, removeLevelLanguage } =
		ManageStorageLevelLanguage()
	const { getGameLanguage, removeGameLanguage } = ManageStorageGameLanguage()

	// RESET SCROLL
	window.scroll(0, 0)

	let typeContextSpace = localStorage.getItem('TYPE_CONTEXT_SPACE')
	let idScene = localStorage.getItem('ID_SCENE')
	let itemDesigner = localStorage.getItem('ITEM_DESIGNER')

	// VALIDATE PREVIOUS ROUTE
	const navigate = useNavigate()
	const validateRoute = () => {
		if (
			localStorage.getItem('TYPE_CONTEXT_SPACE') == null ||
			localStorage.getItem('ID_SCENE') == null ||
			localStorage.getItem('ITEM_DESIGNER') == null
		) {
			navigate('/editor')
		}
	}
	useEffect(() => {
		validateRoute()
		if (itemDesigner === 'StoryTeller') {
			setStatusStoryTeller(true)
		} else if (itemDesigner === 'Decorations') {
			setStatusDecorations(true)
		} else if (itemDesigner === 'Doors') {
			setStatusDoors(true)
		} else if (itemDesigner === 'Npcs') {
			setStatusNpc(true)
		}
	}, [])

	// GET DETAIL SCENE
	const [dataScene, setDataScene] = useState(undefined)

	const [statusNpc, setStatusNpc] = useState(false)
	const [statusDoors, setStatusDoors] = useState(false)
	const [statusStoryTeller, setStatusStoryTeller] = useState(false)
	const [statusDecorations, setStatusDecorations] = useState(false)
	const [validateLevelLanguage, setValidateLevelLanguage] = useState(null)

	useEffect(() => {
		const fetchDataAndSetup = async () => {
			try {
				const obj_game_language = JSON.parse(getGameLanguage())
				const id_level_language = getLevelLanguage()
				if (obj_game_language) {
					const dataLevelLanguage = await verifyLevelLanguage(
						obj_game_language.id_game_language
					)

					const isLanguageInList = Object.values(
						dataLevelLanguage.results
					).some(
						(item) => item.id_level_language === id_level_language
					)
					if (isLanguageInList) {
						const data = await getDataUser()
						myCookies.set.gameUser(data)
						setGameUser(data)
						if ('view_administrator_game' in data?.permissions) {
							getDetailWorldSubworld()
						}
						setValidateLevelLanguage(true)
					} else {
						removeLevelLanguage()
						removeGameLanguage()
						navigate('/editor')
					}
				} else {
					navigate('/editor')
				}
			} catch (error) {
				console.error('Error during data fetching and setup:', error)
			}
		}
		fetchDataAndSetup()
	}, [])

	const verifyLevelLanguage = async (id_game_language) => {
		const resultData = await axiosSupreme(
			'get',
			API_MANAGE_LIST_LEVELS_LANGUAGES,
			id_game_language
		)
		return resultData
	}

	const getDetailWorldSubworld = async () => {
		const result_data =
			typeContextSpace === 'World'
				? await axiosSupreme(
						'get',
						API_MANAGE_SCENE_WORLDS + idScene + '/',
						undefined
				  )
				: await axiosSupreme(
						'get',
						API_MANAGE_SCENE_SUB_WORLDS + idScene + '/',
						undefined
				  )
		setDataScene(result_data)
	}

	const getDataUser = async () => {
		try {
			const response = await axiosSupreme(
				'get',
				USER_API,
				myCookies.get.gameLanguageID()
			)
			myCookies.set.gameUser(response.data)
			return response.data
		} catch (e) {
			if (!window.location.pathname.includes('/editor'))
				window.location.replace('/')
		}
	}

	const isEmpty = (obj) => {
		return JSON.stringify(obj) === '{}'
	}

	const clickItem = (setFuntion) => {
		setStatusNpc(false)
		setStatusDecorations(false)
		setStatusDoors(false)
		setStatusStoryTeller(false)
		setFuntion(true)
		if (setFuntion === setStatusNpc) {
			localStorage.setItem('ITEM_DESIGNER', 'Npcs')
		} else if (setFuntion === setStatusDecorations) {
			localStorage.setItem('ITEM_DESIGNER', 'Decorations')
		} else if (setFuntion === setStatusDoors) {
			localStorage.setItem('ITEM_DESIGNER', 'Doors')
		} else if (setFuntion === setStatusStoryTeller) {
			localStorage.setItem('ITEM_DESIGNER', 'StoryTeller')
		}
	}

	return (
		<div className='objectsAdmin'>
			{!isEmpty(gameUser) &&
			validateLevelLanguage &&
			'view_administrator_game' in gameUser.permissions ? (
				<>
					<Alert />
					<Modal />
					<ModalDelete />
					<BackButton url={'/editor'} />
					<div className='objectAdmin__containerGen'>
						<div className='objectsAdmin__containerGen__title'>
							<TitleAdministratorAndMenu
								name={
									typeContextSpace === 'World'
										? 'Escenas de mundo'
										: 'Escenas de submundo'
								}
							/>
							{typeContextSpace === 'World' ? (
								<h2>
									{' '}
									{dataScene !== undefined
										? dataScene.world.order +
										  '. ' +
										  dataScene.world.name +
										  ' - ' +
										  dataScene.name
										: null}{' '}
								</h2>
							) : (
								<h1>
									{' '}
									{dataScene !== undefined
										? dataScene.sub_world.order +
										  '. ' +
										  dataScene.sub_world.name +
										  ' - ' +
										  dataScene.name
										: null}{' '}
								</h1>
							)}
						</div>

						<div className='objectsAdmin__containerGen__btns'>
							<button
								className={
									statusDecorations
										? 'editorBtnActionAddActive'
										: 'editorBtnActionAdd'
								}
								onClick={() => clickItem(setStatusDecorations)}>
								<p>Decoraciones</p>
							</button>
							<button
								className={
									statusDoors
										? 'editorBtnActionAddActive'
										: 'editorBtnActionAdd'
								}
								onClick={() => clickItem(setStatusDoors)}>
								<p>Puertas</p>
							</button>
							{typeContextSpace === 'Subworld' ? (
								<>
									<button
										className={
											statusStoryTeller
												? 'editorBtnActionAddActive'
												: 'editorBtnActionAdd'
										}
										onClick={() =>
											clickItem(setStatusStoryTeller)
										}>
										<p>Narrador</p>
									</button>
									<button
										className={
											statusNpc
												? 'editorBtnActionAddActive'
												: 'editorBtnActionAdd'
										}
										onClick={() => clickItem(setStatusNpc)}>
										<p>Npcs</p>
									</button>
								</>
							) : null}
						</div>

						{statusDecorations && (
							<ListDecorationsScene idScene={idScene} />
						)}

						{statusDoors && <ListDoorScene idScene={idScene} />}

						{statusStoryTeller && (
							<ListStoryTeller idScene={idScene} />
						)}

						{statusNpc && <NpcAppearanceScene idScene={idScene} />}
					</div>
				</>
			) : (
				<ErrorPerms />
			)}
		</div>
	)
}
