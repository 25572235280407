import regresoAlAula from '../../assets/images/GameResults/regresoAlAula.png'
import regresoAlAulaWebp from '../../assets/images/GameResults/regresoAlAula.webp'
import regresoAlAulaAvif from '../../assets/images/GameResults/regresoAlAula.avif'

export function ModalQuestionGoToClassroom({ onClose }) {
	const handlerGoToClassroom = () => {
		const url = new URL(window.location.href)
		const searchParams = new URLSearchParams(url.search)
		const fromUrl = searchParams.get('from')

		const urlOfClassroom = new URL(fromUrl)
		if (urlOfClassroom.search !== '') {
			let redirectUrl = urlOfClassroom.origin + urlOfClassroom.pathname
			const searchParamsRedirectUrl = new URLSearchParams(
				urlOfClassroom.search
			)
			searchParamsRedirectUrl.append('navigateGame', 'true')
			redirectUrl += `?${searchParamsRedirectUrl.toString()}`
			window.location.href = redirectUrl
		} else {
			window.location.href = `${fromUrl}?navigateGame=true`
		}
	}

	return (
		<div className='containerModalQuestionGoToClassroom'>
			<div className='modalQuestionGoToClassroom'>
				<header className='containerHeaderModalQuestionGoToClassroom'>
					<picture className='containerImageReturnClassroom'>
						<source srcSet={regresoAlAulaAvif} type='image/avif' />
						<source srcSet={regresoAlAulaWebp} type='image/webp' />
						<img
							src={regresoAlAula}
							alt='teoloro con un tablero atrás de él'
							className='imageReturnClassroom'
						/>
					</picture>
				</header>

				<div className='containerBodyModalQuestionGoToClassroom'>
					<p className='textModalQuestionGoToClassroom'>
						Quieres regresar al aula?
					</p>

					<div className='containerButtonsModalQuestionGoToClassroom'>
						<button
							className='playground__results__card__buttons__button buttonModalQuestionGoToClassroom'
							onClick={handlerGoToClassroom}>
							SI
						</button>

						<button
							className='playground__results__card__buttons__button buttonModalQuestionGoToClassroom'
							onClick={onClose}>
							NO
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
