import React, { useEffect, useState } from 'react'
import useAuthNavigator from '../context/AuthProvider/useAuthNavigator'
import { useNavigate } from 'react-router-dom'

import myCookies from '../utils/myCookies'
import { useAuthProvider } from '../context/AuthProvider/AuthProvider'
import '../styles/Start.scss'

import startImageAvif from '../assets/images/start.avif'
import startImagePng from '../assets/images/start.png'
import teodoroImageAvif from '../assets/images/teodoro1.avif'
import teodoroImagePng from '../assets/images/teodoro1.png'
import teoloroLettersImageAvif from '../assets/images/teoloroLetters.avif'
import teoloroLettersImagePng from '../assets/images/teoloroLetters.png'
import trailer from '../assets/videos/1080introjuego.mp4'
import trailerWebm from '../assets/videos/1080introjuego.webm'
import CompanyBar from '../components/shared/CompanyBar/CompanyBar'
import { useDeleteSpecificQueryParams } from '../hooks/useDeleteSpecificQueryParams/useDeleteSpecificQueryParams'
import { useUserSubscription } from '../hooks/useUserSubscription/useUserSubscription'

const Start = () => {
	return useAuthNavigator(<PrivatePage />)
}

function PrivatePage() {
	const navigate = useNavigate()
	const {
		gameUser,
		gameLanguageOptions,
		updateGameUser,
		updateGameLanguages,
	} = useAuthProvider()

	const [selectedGameLanguage, setSelectedGameLanguage] = useState()
	// myCookies.get.gameLanguageID()
	const [showTrailer, setShowTrailer] = useState(false)
	const { deleteQueryParam } = useDeleteSpecificQueryParams()
	const { idUserSubscription, isLoading } = useUserSubscription()

	useEffect(() => {
		if (isLoading) return

		deleteQueryParam('cache')
		deleteQueryParam('idUserSubscription')

		const init = async () => {
			// ----- #1. GET THE GAME LANGUAGES
			const gameLanguageOptionsCopy = await updateGameLanguages(idUserSubscription)
			// ----- #2 . IF THERE IS NO GAME_LANGUAGE_ID THEN ASSING IT THE
			// FIRST GAME LANGUAGE AS ITS INIT DEFAULT VALUE
			if (!gameLanguageOptionsCopy) return

			if (
				!myCookies.get.gameLanguageID() ||
				myCookies.get.gameLanguageID() === 'undefined'
			) {
				myCookies.set.gameLanguageID(
					gameLanguageOptionsCopy[0]?.game_language.id_game_language
				)
			}
			// ----- #3. SET THE FIRST GAME USER DATA
			// updateGameUser()
		}
		init()
	}, [idUserSubscription, isLoading])

	const onStart = () => {
		if (gameUser.saw_trailer) {
			navigate('/game')
		} else {
			setShowTrailer(true)
		}
	}

	const onSelectChange = (idGameLanguage) => {
		setSelectedGameLanguage(idGameLanguage)
		myCookies.set.gameLanguageID(idGameLanguage)
		updateGameUser()
	}

	const endTutorial = () => {
		navigate('/game')
	}

	return (
		<div className='start'>
			{showTrailer && (
				<div className='containerTutorial'>
					<video
						autoPlay
						className='containerTutorialVideo'
						onEnded={endTutorial}>
						<source src={trailerWebm} type='video/webm' />
						<source src={trailer} type='video/mp4' />
					</video>

					<button
						className='containerTutorialSkipTutorial'
						onClick={endTutorial}>
						SKIP
					</button>
				</div>
			)}
			<div className='start__company__bar'>
				<CompanyBar />
			</div>
			<div className='start__page'>
				<picture className='start__teodoro'>
					<source srcSet={teodoroImageAvif} type='image/avif' />
					<img
						src={teodoroImagePng}
						alt='pájaro teoloro'
						loading='lazy'
						decoding='async'
					/>
				</picture>
				<div className='start__subscriptions'>
					{gameLanguageOptions?.map((sub, index) => (
						<button
							onClick={() =>
								onSelectChange(
									sub.game_language.id_game_language
								)
							}
							className={`start__subscriptions__button ${
								selectedGameLanguage ===
								sub.game_language.id_game_language
									? 'start__subscriptions__selected'
									: ''
							}`}
							key={index}>
							<picture className='start__subscriptions__container'>
								<img
									className='start__subscriptions__container__flag1'
									src={
										sub.game_language
											.language_to_learn_image
									}
									alt={`${sub.game_language.language_to_learn}flag`}
									loading='lazy'
									decoding='async'
								/>
								<img
									className='start__subscriptions__container__flag2'
									src={sub.game_language.base_language_image}
									alt={`${sub.game_language.base_language}flag`}
									loading='lazy'
									decoding='async'
								/>
							</picture>
						</button>
					))}
				</div>
				<div className='start__right'>
					<picture>
						<source
							srcSet={teoloroLettersImageAvif}
							type='image/avif'
						/>
						<img
							src={teoloroLettersImagePng}
							alt='Teoloro'
							loading='lazy'
							decoding='async'
						/>
					</picture>
					<button
						className={`start__right__start__button ${
							selectedGameLanguage == null
								? 'start__right__start__button__hidden'
								: ''
						}`}
						onClick={onStart}>
						<picture>
							<source srcSet={startImageAvif} type='image/avif' />
							<img
								src={startImagePng}
								alt='Botón de Inicio'
								loading='lazy'
								decoding='async'
							/>
						</picture>
					</button>
				</div>
			</div>
		</div>
	)
}

export default Start
