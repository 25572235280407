import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ACCOUNT_FRONTEND } from '../../urls'

export function useUserSubscription() {
  const [idUserSubscription, setIdUserSubscription] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [params] = useSearchParams()

  useEffect(() => {
    const idUserSubscriptionLS =
      window.localStorage.getItem('idUserSubscription')
    const newIdUserSubscription =
      params.get('idUserSubscription') ?? idUserSubscriptionLS

    if (newIdUserSubscription == null) {
      window.location.href = `${ACCOUNT_FRONTEND}?cache=${new Date().getTime()}`
      return
    }

    setIdUserSubscription(newIdUserSubscription)
    window.localStorage.setItem('idUserSubscription', newIdUserSubscription)
    setIsLoading(false)
  }, [])

  const handlerSelectedSubscription = (newIdUserSubscription) => {
    window.location.href = `/?idUserSubscription=${newIdUserSubscription}`
  }

  return {
    idUserSubscription,
    isLoading,
    handlerSelectedSubscription,
  }
}
