import { useEffect, useState } from 'react'
import { LIST_USER_SUBSCRIPTION } from '../../utils/constants'
import { useAuthProvider } from '../../context/AuthProvider/AuthProvider'

export function useListSubscriptions() {
  const [listSubscriptions, setListSubscriptions] = useState([])
  const { axiosSupreme } = useAuthProvider()

  useEffect(() => {
    axiosSupreme('get', LIST_USER_SUBSCRIPTION)
      .then((res) => {
        setListSubscriptions(res)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  return {
    listSubscriptions,
  }
}
