import { useListSubscriptions } from '../../hooks/useListSubscriptions/useListSubscriptions'
import { useUserSubscription } from '../../hooks/useUserSubscription/useUserSubscription'
import './ListSubscriptions.scss'

export function ListSubscriptions() {
  const { idUserSubscription, handlerSelectedSubscription } =
    useUserSubscription()
  const { listSubscriptions } = useListSubscriptions()

  return (
    <div className="sectionListBtnSubscriptions">
      <div className="listBtnSubscriptions">
        {listSubscriptions.map((dataSubscription) => {
          const { id_subscription: idSubscription, image_file: imageFile } =
            dataSubscription.subscription

          const idUserSubscriptionSelected =
            dataSubscription.user_subscription.id_user_subscription
          const isCurrentSubscriptionSelected =
            idUserSubscription === idUserSubscriptionSelected

          return (
            <div key={idSubscription} className="containerBtnSubscription">
              {isCurrentSubscriptionSelected ? (
                <div className="subscriptionSelected"></div>
              ) : (
                <div className="subscriptionBackground"></div>
              )}

              <button
                onClick={() =>
                  handlerSelectedSubscription(idUserSubscriptionSelected)
                }
                className={`btnSubscription ${
                  isCurrentSubscriptionSelected
                    ? 'btnSubscriptionIsCurrentSelected'
                    : ''
                }`}
              >
                <picture>
                  <img
                    src={imageFile}
                    alt="flag"
                    decoding="async"
                    role="presentation"
                    className="btnSubscriptionFlag"
                  />
                </picture>
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}
