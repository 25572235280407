export function useDeleteSpecificQueryParams() {
  const deleteQueryParam = (queryParam) => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)

    if (searchParams.size === 1 && searchParams.get(queryParam)) {
      window.history.replaceState(null, null, window.location.pathname)
    } else if (searchParams.size >= 1) {
      searchParams.delete(queryParam)
      window.history.replaceState(null, null, `${window.location.pathname}?${searchParams.toString()}`)
    }
  }

  return {
    deleteQueryParam
  }
}
