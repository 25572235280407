import { useNavigate, useParams } from 'react-router-dom'
import { SceneSwitcher } from '../components/SceneSwitcher'
import useAuthNavigator from '../context/AuthProvider/useAuthNavigator'

import myCookies from '../utils/myCookies'

const Game = () => {
	return useAuthNavigator(<PrivatePage />)
}

function PrivatePage(props) {
	const params = useParams()
	const navigate = useNavigate()

	if (params.id_sub_world_door)
		// any value is valid for the execute initial app
		myCookies.set.gameLanguageID('0')
	else if (!myCookies.get.gameLanguageID()) navigate('/')

	return <SceneSwitcher />
}
export default Game
