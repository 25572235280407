import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Start from '../pages/Start'
import Game from '../pages/Game'
import { Error } from '../pages/Error404'
import { AuthProvider } from '../context/AuthProvider/AuthProvider'
import '../styles/Apps.scss'
import ModalProvider from '../provider/ModalProvider'
import AlertProvider from '../provider/AlertProvider'
import Editor from '../pages/Administration/Design/Editor'
import ManageSceneWorld from '../pages/Administration/Design/ManageSceneWorld'
import ManageNpc from '../pages/Administration/Design/ManageNpc'
import DeleteProvider from '../provider/DeleteProvider'
import ManageAnimations from '../pages/Administration/Design/ManageAnimations'
import ManageSound from '../pages/Administration/Design/ManageSound'
import ManageChallengeGame from '../pages/Administration/Design/ManageChallengeGame'
import SystemLanguageProvider from '../context/SystemLanguageProvider'

function App() {
	return (
		<AlertProvider>
			<ModalProvider>
				<DeleteProvider>
					<AuthProvider>
						<SystemLanguageProvider>
							<RoutesContainer />
						</SystemLanguageProvider>
					</AuthProvider>
				</DeleteProvider>
			</ModalProvider>
		</AlertProvider>
	)
}

const RoutesContainer = () => (
	<BrowserRouter>
		<Routes>
			<Route path='/' element={<Start />} />
			<Route path='/game' element={<Game />} />
			<Route path='/game/:id_sub_world_door' element={<Game />} />

			<Route path='/editor' element={<Editor />} />
			<Route path='/manage-scene-world' element={<ManageSceneWorld />} />
			<Route path='/manage-animations' element={<ManageAnimations />} />
			<Route path='/manage-npcs' element={<ManageNpc />} />
			<Route
				path='/manage-challenge-game'
				element={<ManageChallengeGame />}
			/>
			<Route path='/manage-sound' element={<ManageSound />} />
			{/* <Route path='/user-admin' element={<UserAndGroupAdministrator />} /> */}
			<Route path='*' element={<Error />} />
		</Routes>
	</BrowserRouter>
)
export default App
