import { useEffect, useId, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import appChatBlueIcon from '../../../assets/icons/appChatBlue.png'
import appClassroomBlueIcon from '../../../assets/icons/appClassroomBlue.png'
import appGameBlueIcon from '../../../assets/icons/appGameBlue.png'
import dockIcon from '../../../assets/icons/dock.png'
import brandIcon from '../../../assets/icons/logo.svg'
import userIcon from '../../../assets/icons/user.png'
import '../../../styles/pages/shared/SubscriptionsDialogue.scss'
import '../../../styles/components/shared/CompanyBar.scss'
import { Loader } from '../../../components/Loader/Loader'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import { useSendDataUser } from '../../../hooks/useSendDataUser/useSendDataUser'
import {
	IconConfig,
	IconEquis,
	IconPencil,
	IconPlay,
} from '../../../icons/icons'
import {
	CHAT_FRONTEND,
	CLASS_FRONTEND,
	GAME_BACKEND,
	GAME_FRONTEND,
} from '../../../urls'
import { LIST_IMAGE_PROFILE_NPC } from '../../../utils/constants'
import { detectarFirefox } from '../../../utils/detectarNavigators'
import Icon from '../Icon/Icon'
import IconButton from '../IconButton/IconButton'
import { getRealUrl } from '../../../utils/getRealUrl'
import { ListSubscriptions } from '../../ListSubscriptions/ListSubscriptions'

const CompanyBar = ({ showOnlyLogo = false }) => {
	const { t } = useTranslation()

	const { user, updateGameUser } = useAuthProvider()
	const [imageOfUser, setImageOfUser] = useState({
		src: user.npc ? getRealUrl(user.npc, GAME_BACKEND) : userIcon,
	})

	useEffect(() => {
		setImageOfUser({
			src: user.npc ? getRealUrl(user.npc, GAME_BACKEND) : userIcon,
		})
	}, [user])

	const handlerErrorLoadImage = async () => {
		await updateGameUser()
	}

	return (
		<section className='companyBar'>
			<a
				className='companyBarBrand'
				href='https://teoloro.com/'
				target='_blank'
				rel='noreferrer noopener'>
				<Icon
					src={brandIcon}
					size={'3rem'}
					handlerErrorLoadImage={handlerErrorLoadImage}
				/>
				<span>
					{t('pages.initial.components.header.first_name')}
					<span>
						{t('pages.initial.components.header.last_name')}
					</span>
				</span>
			</a>

			<ListSubscriptions />

			{!showOnlyLogo && (
				<div className='companyBarButtons'>
					<Dropdown
						icon={dockIcon}
						stylesDisabled={true}
						ariaLabel={t(
							'pages.initial.components.element_attributes.menu.arial_label'
						)}
						title={t(
							'pages.initial.components.element_attributes.menu.title'
						)}>
						<AppsContainer />
					</Dropdown>
					<Dropdown
						icon={imageOfUser.src}
						profileWithSubscriptionsFlags={true}
						ariaLabel={t(
							'pages.initial.components.element_attributes.configuration.arial_label'
						)}
						title={t(
							'pages.initial.components.element_attributes.configuration.title'
						)}
						handlerErrorLoadImage={handlerErrorLoadImage}>
						<ProfileContainer />
					</Dropdown>
				</div>
			)}
		</section>
	)
}

const Dropdown = ({
	children,
	icon,
	stylesDisabled,
	profileWithSubscriptionsFlags,
	redDot,
	ariaLabel,
	title,
	handlerErrorLoadImage,
}) => {
	const [containerIsVisible, setContainerIsVisible] = useState(false)
	const dropdownRef = useRef(null)

	// ----- $5. CLOSE THE NOTIFICATIONS WHEN CLICK OUTSIDE OF THE CONTAINER.
	useEffect(() => {
		function a(e) {
			if (dropdownRef.current) {
				if (!dropdownRef.current.contains(e.target)) {
					setContainerIsVisible(false)
				}
			}
		}
		window.addEventListener('click', a)

		return () => {
			window.removeEventListener('click', a)
		}
	}, [containerIsVisible])

	return (
		<div className='companyBarDropdown' ref={dropdownRef}>
			<IconButton
				className='companyBarDropdownButton'
				onClick={() => {
					setContainerIsVisible((v) => !v)
				}}
				src={icon}
				size={'2.2rem'}
				ariaLabel={ariaLabel}
				title={title}
				handlerErrorLoadImage={handlerErrorLoadImage}
			/>
			{containerIsVisible && (
				<div
					className='companyBarDropdownContainer'
					style={
						stylesDisabled && {
							overflow: 'visible',
							boxShadow: 'none',
							backgroundColor: 'transparent',
						}
					}>
					{children}
				</div>
			)}
		</div>
	)
}

const AppsContainer = () => {
	const apps = [
		{
			icon: appClassroomBlueIcon,
			to: CLASS_FRONTEND,
		},
		{
			icon: appChatBlueIcon,
			to: CHAT_FRONTEND,
		},
		{
			icon: appGameBlueIcon,
			to: GAME_FRONTEND,
		},
	]

	return (
		<div className='companyBarAppsContainer'>
			<div className='companyBarAppsContainerMain'>
				{apps.map((app, appIndex) => (
					<DockAppItem
						key={appIndex}
						arrayLength={apps.length}
						data={app}
						index={appIndex}
					/>
				))}
			</div>
		</div>
	)
}

const DockAppItem = ({ arrayLength, data, index }) => {
	return (
		<div key={index} className='companyBarAppsContainerSection'>
			<div
				className={
					'companyBarAppsContainerSectionHighlight' +
					(index === 0
						? ' companyBarAppsContainerSectionHighlightLeft'
						: index === arrayLength - 1
						? ' companyBarAppsContainerSectionHighlightRight'
						: '')
				}>
				<a
					href={`${data.to}?cache=${new Date().getTime()}`}
					target='_blank'
					rel='noopener noreferrer'>
					<Icon
						className='companyBarAppsContainerSectionIcon'
						src={data.icon}
					/>
				</a>
			</div>
		</div>
	)
}

const ProfileContainer = () => {
	const { t } = useTranslation()
	const { user, logout, updateGameUser } = useAuthProvider()
	// const [subscriptionsAreOpened, setSubscriptionsAreOpened] = useState(false)
	const [iconPlayDataUser, setIconPlayDataUser] = useState(false)
	const [iconPlayConfig, setIconPlayConfig] = useState(false)
	const [showProfilePicture, setShowProfilePicture] = useState(false)
	const [listImagesProfile, setListImagesProfile] = useState([])
	const [imageOfUser, setImageOfUser] = useState({
		src: user.npc ? getRealUrl(user.npc, GAME_BACKEND) : userIcon,
	})
	const [indexImgOver, setIndexImgOver] = useState(null)
	const nextImagesProfile = useRef(undefined)
	const requestInProcess = useRef(false)
	const initialImageOfUser = useRef(
		user.npc ? getRealUrl(user.npc, GAME_BACKEND) : userIcon
	)
	const buttonSetImageProfileRef = useRef()
	const { axiosSupreme } = useAuthProvider()
	const { sendDataUser, loading } = useSendDataUser()

	useEffect(() => {
		const isFirefox = detectarFirefox()
		if (isFirefox) {
			buttonSetImageProfileRef.current.style.bottom = '-100px'
			buttonSetImageProfileRef.current.style.right = '-100px'
		}
	}, [])

	useEffect(() => {
		setImageOfUser({
			src: user.npc ? getRealUrl(user.npc, GAME_BACKEND) : userIcon,
		})
	}, [user])

	useEffect(() => {
		if (!showProfilePicture) {
			nextImagesProfile.current = undefined
			requestInProcess.current = false
			setImageOfUser({ src: initialImageOfUser.current })
			return
		}

		const intersectionObserver = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && !requestInProcess.current) {
					getListImagesProfileNPC()
				}
			},
			{
				threshold: 0.1,
			}
		)

		const sapo = document.getElementById('sapo')
		intersectionObserver.observe(sapo)
	}, [showProfilePicture])

	const getListImagesProfileNPC = async () => {
		if (nextImagesProfile.current === null) return

		requestInProcess.current = true
		const urlOfRequest = nextImagesProfile.current ?? LIST_IMAGE_PROFILE_NPC
		const imagesProfile = await axiosSupreme('get', urlOfRequest)

		nextImagesProfile.current = imagesProfile.next

		if (
			imagesProfile.next != null &&
			!imagesProfile.next.startsWith('https')
		) {
			nextImagesProfile.current = imagesProfile.next.replace(
				'http',
				'https'
			)
		}

		setListImagesProfile((prevImages) => [
			...prevImages,
			...imagesProfile.results,
		])
		requestInProcess.current = false
	}

	/**
	 * ¿Qué debe hacer exactamente el boton de las suscripciones?
	 *
	 * Ese es un componente, es un componente como cualquier otro.
	 * Que la primera vez que aparece crea el dialogue.
	 */

	// const openSubscriptionsDialogue = () => {
	//   setSubscriptionsAreOpened(true)
	// }
	// const closeSubscriptionsDialogue = () => {
	//   setSubscriptionsAreOpened(false)
	// }

	const displayAndHiddenDataUserContent = () => {
		if (iconPlayDataUser) {
			setIconPlayDataUser(false)
		} else {
			setIconPlayDataUser(true)
			setIconPlayConfig(false)
		}
	}

	const displayAndHiddenConfigurationContent = () => {
		if (iconPlayConfig) {
			setIconPlayConfig(false)
		} else {
			setIconPlayConfig(true)
			setIconPlayDataUser(false)
		}
	}

	const displayProfilePicture = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setShowProfilePicture((showPicture) => !showPicture)
	}

	const handlerSelectedImage = (imageSelected) => {
		setImageOfUser({
			src: getRealUrl(imageSelected.silence_file, GAME_BACKEND),
			idNpc: imageSelected.id_npc,
		})
	}

	const handlerOnMouseOver = (indexOfImage) => {
		setIndexImgOver(indexOfImage)
	}

	const handlerOnMouseLeave = () => {
		setIndexImgOver(null)
	}

	const submitImageSelected = () => {
		if (!imageOfUser.idNpc) return

		initialImageOfUser.current = imageOfUser.src

		sendDataUser({
			dataForm: {
				npc: imageOfUser.idNpc,
			},
		})
	}

	const handlerErrorLoadImage = async () => {
		await updateGameUser()
	}

	return (
		<div className='companyBarProfileContainer'>
			<div className='companyBarProfileContainerImage'>
				<picture className='companyBarProfileContainerImageContainer'>
					<img
						src={imageOfUser.src}
						alt={t(
							'pages.initial.element_attributes.user.img_user'
						)}
						loading='lazy'
						decoding='async'
						onError={handlerErrorLoadImage}
					/>
					<button
						className='companyBarProfileContainerImageContainerEdit'
						onClick={displayProfilePicture}
						ref={buttonSetImageProfileRef}>
						{showProfilePicture ? <IconEquis /> : <IconPencil />}
					</button>
				</picture>
			</div>
			{showProfilePicture ? (
				<div className='sectionContainerImage'>
					<div className='sectionContainerImageProfile'>
						{listImagesProfile.map((img, imgIndex) => (
							<PictureProfile
								img={img.silence_file}
								alt={img.description}
								handlerSelectedImage={() =>
									handlerSelectedImage(img)
								}
								handlerOnMouseOver={() =>
									handlerOnMouseOver(imgIndex)
								}
								handlerOnMouseLeave={handlerOnMouseLeave}
								isNotHover={
									indexImgOver != null &&
									indexImgOver !== imgIndex
								}
								key={img.id_npc}
							/>
						))}
						<div id='sapo' />
					</div>
					{requestInProcess.current && <LoadingSpinnerImages />}

					<div>
						<button
							className='formPersonalDataUserButtonSubmit'
							onClick={submitImageSelected}
							disabled={
								loading ||
								!imageOfUser.idNpc ||
								initialImageOfUser.current === imageOfUser.src
							}>
							{t('common.btn_save_data')}
						</button>
					</div>
				</div>
			) : (
				<div className='companyBarProfileContainerData'>
					<DataUser
						displayAndHiddenDataUserContent={
							displayAndHiddenDataUserContent
						}
						iconPlayDataUser={iconPlayDataUser}
					/>
					<ConfigBar
						displayAndHiddenConfigurationContent={
							displayAndHiddenConfigurationContent
						}
						iconPlayConfig={iconPlayConfig}
					/>
				</div>
			)}
			<div className='companyBarProfileContainerLogout'>
				<button
					className='companyBarProfileContainerLogoutButton'
					onClick={logout}>
					{t('pages.initial.components.profile.log_out')}
				</button>
			</div>
			{/* {subscriptionsAreOpened && (
        <SubscriptionsDialogue onClose={closeSubscriptionsDialogue} />
      )}
      <div className="companyBarProfileContainerUpperBox">
        <a href={ACCOUNT_FRONTEND} target="_blanck">
          <IconButton style={{ cursor: 'pointer' }} src={menuIcon} size={24} />
        </a>
      </div>
      <a href={ACCOUNT_FRONTEND} target="_blanck">
        <Icon
          className="companyBarProfileContainerImage"
          src={userIcon}
          size={64}
        />
      </a>
      <div className="companyBarProfileContainerText">
        {user.name ? user.name + ' ' + user.last_name : 'null'}
      </div>
      <div className="companyBarProfileContainerText">{user.email}</div>
      <div
        className="companyBarProfileContainerSubscription"
        onClick={openSubscriptionsDialogue}
        onKeyDown={() => {}}
        tabIndex={0}
        role="button"
      >
        <span className="companyBarProfileContainerSubscriptionText">
          Suscripción
        </span>
        <div className="companyBarProfileContainerSubscriptionCircle">
          <SubscriptionFlags
            fromFlagIcon={flagSpainIcon}
            toFlagIcon={flagFinlandIcon}
          />
        </div>
      </div>
      <div
        className="companyBarProfileContainerLogout"
        onClick={logout}
        onKeyDown={() => {}}
        tabIndex={0}
        role="button"
      >
        Cerrar Sesión
      </div> */}
		</div>
	)
}

export function DataUser({
	displayAndHiddenDataUserContent,
	iconPlayDataUser,
}) {
	const { t } = useTranslation()
	const { user } = useAuthProvider()
	const { sendDataUser, updateErorrs, errors, loading, responseRequest } =
		useSendDataUser()
	const [dataForm, setDataForm] = useState({
		name: user.name ?? '',
		last_name: user.last_name ?? '',
		username: user.username ?? '',
	})
	const dataUserContentRef = useRef()

	useEffect(() => {
		if (!iconPlayDataUser) {
			dataUserContentRef.current.style.maxHeight = '0'
		}
	}, [iconPlayDataUser])

	useEffect(() => {
		if (responseRequest) {
			updateErorrs({ errors: [] })
		}
	}, [responseRequest])

	const handlerSubmitDataUser = async (e) => {
		e.preventDefault()
		await sendDataUser({ dataForm })
	}

	const handlerChangeDataForm = (e) => {
		const { value, name } = e.target
		setDataForm((prevData) => ({
			...prevData,
			[name]: value,
		}))
	}

	const handlerDisplayAndHiddenDataUserContent = () => {
		displayAndHiddenDataUserContent()

		if (
			dataUserContentRef.current.style.maxHeight !== '0px' &&
			dataUserContentRef.current.style.maxHeight !== ''
		) {
			dataUserContentRef.current.style.maxHeight = '0'
		} else {
			const height = dataUserContentRef.current.scrollHeight
			dataUserContentRef.current.style.maxHeight = `${height + 500}px`
		}
	}

	return (
		<div className='companyBarProfileContainerDataUser'>
			<div
				className='companyBarProfileContainerDataUserButton'
				onClick={handlerDisplayAndHiddenDataUserContent}
				onKeyDown={handlerDisplayAndHiddenDataUserContent}
				role='button'
				tabIndex={0}>
				<picture className='companyBarProfileContainerDataUserButtonContainer'>
					<img
						src={userIcon}
						alt={t(
							'pages.initial.element_attributes.user.img_user'
						)}
						decoding='async'
					/>
				</picture>
				<div className='companyBarProfileContainerDataUserButtonContainerText'>
					<p>
						{t(
							'pages.initial.components.profile.personal_data.title'
						)}
					</p>
				</div>
				<picture
					className={`companyBarProfileContainerDataUserButtonContainerArrow ${
						iconPlayDataUser
							? 'companyBarProfileContainerDataUserButtonContainerArrowRotate'
							: ''
					}`}>
					<IconPlay />
				</picture>
			</div>
			<div
				ref={dataUserContentRef}
				className='companyBarProfileContainerDataUserContainer'>
				<form
					className='formPersonalDataUser'
					onSubmit={handlerSubmitDataUser}>
					<label className='formPersonalDataUserLabel'>
						{t(
							'pages.initial.components.profile.personal_data.name'
						)}
						<input
							type='text'
							onChange={handlerChangeDataForm}
							value={dataForm.name}
							name='name'
						/>
					</label>
					<label className='formPersonalDataUserLabel'>
						{t(
							'pages.initial.components.profile.personal_data.last_name'
						)}
						<input
							type='text'
							onChange={handlerChangeDataForm}
							value={dataForm.last_name}
							name='last_name'
						/>
					</label>
					<label className='formPersonalDataUserLabel'>
						{t(
							'pages.initial.components.profile.personal_data.user_name'
						)}
						<input
							type='text'
							onChange={handlerChangeDataForm}
							value={dataForm.username}
							name='username'
						/>
					</label>

					{errors.length > 0 && !loading && (
						<div className='formPersonalDataUserContainerErrors'>
							{errors.map((theError, indexError) => (
								<p key={indexError}>{theError}</p>
							))}
						</div>
					)}

					{responseRequest !== '' && !loading && (
						<div className='formPersonalDataUserContainerSuccess'>
							<p>{responseRequest}</p>
						</div>
					)}

					{loading && (
						<div className='formPersonalDataUserContainerLoader'>
							<Loader />
						</div>
					)}

					<button
						className='formPersonalDataUserButtonSubmit'
						type='submit'
						disabled={loading}>
						{t('common.btn_save_data')}
					</button>
				</form>
			</div>
		</div>
	)
}

export function ConfigBar({
	displayAndHiddenConfigurationContent,
	iconPlayConfig,
}) {
	const { t } = useTranslation()
	const { user } = useAuthProvider()
	const {
		handlerEditPassword,
		sendDataUser,
		editPassword,
		updateErorrs,
		errors,
		loading,
		responseRequest,
	} = useSendDataUser()
	const [dataForm, setDataForm] = useState({
		email: user.email ?? '',
		password: '',
		confirmPassword: '',
	})
	const configurationContentRef = useRef()
	const idPassword = useId()

	useEffect(() => {
		if (!iconPlayConfig) {
			configurationContentRef.current.style.maxHeight = '0'
		}
	}, [iconPlayConfig])

	useEffect(() => {
		if (responseRequest) {
			setDataForm((prevData) => ({
				...prevData,
				password: '',
				confirmPassword: '',
			}))
			handlerEditPassword()
			updateErorrs({ errors: '' })
		}
	}, [responseRequest])

	const handlerSubmitDataUser = async (e) => {
		e.preventDefault()
		await sendDataUser({ dataForm })
	}

	const handlerChangeDataForm = (e) => {
		const { value, name } = e.target
		setDataForm((prevData) => ({
			...prevData,
			[name]: value,
		}))
	}

	const handlerDisplayAndHiddenConfigurationContent = () => {
		displayAndHiddenConfigurationContent()

		if (
			configurationContentRef.current.style.maxHeight !== '0px' &&
			configurationContentRef.current.style.maxHeight !== ''
		) {
			configurationContentRef.current.style.maxHeight = '0'
		} else {
			const height = configurationContentRef.current.scrollHeight
			configurationContentRef.current.style.maxHeight = `${
				height + 500
			}px`
		}
	}

	return (
		<div className='companyBarProfileContainerDataUser'>
			<div
				className='companyBarProfileContainerDataUserButton'
				onClick={handlerDisplayAndHiddenConfigurationContent}
				onKeyUp={handlerDisplayAndHiddenConfigurationContent}
				role='button'
				tabIndex={0}>
				<picture className='companyBarProfileContainerDataUserButtonContainer'>
					<IconConfig />
				</picture>
				<div className='companyBarProfileContainerDataUserButtonContainerText'>
					<p>
						{t(
							'pages.initial.components.profile.configuration.title'
						)}
					</p>
				</div>
				<picture
					className={`companyBarProfileContainerDataUserButtonContainerArrow ${
						iconPlayConfig
							? 'companyBarProfileContainerDataUserButtonContainerArrowRotate'
							: ''
					}`}>
					<IconPlay />
				</picture>
			</div>
			<div
				ref={configurationContentRef}
				className='companyBarProfileContainerDataUserContainer'>
				<form
					className='formPersonalDataUser'
					onSubmit={handlerSubmitDataUser}>
					<label className='formPersonalDataUserLabel'>
						{t(
							'pages.initial.components.profile.configuration.mail'
						)}
						<input
							type='email'
							onChange={handlerChangeDataForm}
							value={dataForm.email}
							name='email'
						/>
					</label>
					<div className='formPersonalDataUserLabel'>
						<label
							htmlFor={idPassword}
							className='formPersonalDataUserLabel'>
							{t(
								'pages.initial.components.profile.configuration.password'
							)}
						</label>
						<div className='formPersonalDataUserContainerPassword'>
							<input
								type='password'
								id={idPassword}
								onChange={handlerChangeDataForm}
								value={dataForm?.password ?? ''}
								name='password'
								disabled={!editPassword}
							/>
							<button
								className='formPersonalDataUserContainerPasswordContainerPencil'
								onClick={handlerEditPassword}
								type='button'>
								{editPassword ? <IconEquis /> : <IconPencil />}
							</button>
						</div>
					</div>

					{editPassword && (
						<label className='formPersonalDataUserLabel'>
							{t(
								'pages.initial.components.profile.configuration.new_password'
							)}
							<input
								type='password'
								onChange={handlerChangeDataForm}
								value={dataForm?.confirmPassword ?? ''}
								name='confirmPassword'
							/>
						</label>
					)}

					{errors.length > 0 && !loading && (
						<div className='formPersonalDataUserContainerErrors'>
							{errors.map((theError, indexError) => (
								<p key={indexError}>{theError}</p>
							))}
						</div>
					)}

					{responseRequest !== '' && !loading && (
						<div className='formPersonalDataUserContainerSuccess'>
							<p>{responseRequest}</p>
						</div>
					)}

					{loading && (
						<div className='formPersonalDataUserContainerLoader'>
							<Loader />
						</div>
					)}

					<button
						className='formPersonalDataUserButtonSubmit'
						type='submit'
						disabled={loading}>
						{t('common.btn_save_data')}
					</button>
				</form>
			</div>
		</div>
	)
}

function PictureProfile({
	img,
	alt,
	handlerSelectedImage,
	handlerOnMouseOver,
	handlerOnMouseLeave,
	isNotHover,
}) {
	const srcImg = getRealUrl(img)

	return (
		<picture className='sectionContainerImageProfileUser'>
			<img
				src={srcImg}
				alt={alt}
				decoding='async'
				loading='lazy'
				onClick={handlerSelectedImage}
				onMouseOver={handlerOnMouseOver}
				onFocus={handlerOnMouseOver}
				onMouseLeave={handlerOnMouseLeave}
				className={
					isNotHover ? 'sectionContainerImageProfileUserBlurImg' : ''
				}
			/>
		</picture>
	)
}

function LoadingSpinnerImages() {
	return (
		<div className='containerSpinner'>
			<div id='spinner' />
		</div>
	)
}

export default CompanyBar
